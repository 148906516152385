<!--
 * @Author       : JiangChao
 * @Date         : 2022-08-26 19:59:51
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-21 21:01:58
 * @Description  : 綁定email信箱
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <div class="title"><span>綁定用戶資料</span></div>
               <div class="input-moudle">
            <van-field
                class="input"
                v-model="nickName"
                clearable
                placeholder="請輸入暱稱"
            >
            </van-field>
            <van-field
                class="input"
                v-model="email"
                clearable
                placeholder="請輸入電子郵箱"
            >
            </van-field>
              </div>

            <div class="login-way">
                <div class="sms-way" @click="$_submitEmail()">綁定用戶資料</div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import { Toast } from "vant";

export default {
    data() {
        return {
            email: "",
            nickName: "",
        };
    },
     computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    methods: {
        async $_submitEmail() {
            if (!this.nickName) {
                Toast("請輸入暱稱");
                return;
            }
            if (!this.email) {
                Toast("請輸入電子信箱");
                return;
            }

            try {
                const { data } = await this.$axios.post("customer/upUserInfo", {
                    email: this.email,
                    nickName: this.nickName,
                });
                if (data.success) {
                    Toast(data.result);
                    localStorage.setItem("email", this.email);
                    this.$router.push("map");
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
};
</script>

<style scoped lang="less">
.bg {
    text-align: center;
    .logo {
        width: 100px;
        margin-top: 60px;
        height: 100px;
    }
    .plug-inn {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        .other-way {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px;
            justify-content: space-around;
            color: #333333;
            font-weight: 500;
        }
    }
    .input-moudle {
        margin-right: 22px;
        margin-left: 22px;
        .input {
            margin-top: 24px;
            background: #f9f9f9;
            border-radius: 22px;
            margin-bottom: 7px;
            // position: static;
        }
        .line {
            opacity: 0.68;
            width: 1px;
            color: #e1e5e9;
            border-radius: 8px;
            margin-right: 20px;
        }
        .send {
            font-size: 14px;
            font-family: SourceHanSansCN, SourceHanSansCN-Normal;
            font-weight: Normal;
            text-align: center;
            color: #333333;
            line-height: 18px;
        }
    }
}
.title {
    background: #fee26c;
    text-align: center;
    padding: 10px;
}
.read-moudle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 22px;
    margin-top: 20px;
    .clause {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #a1a1a1;
        line-height: 12px;
    }
    .read {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        margin-left: 6px;
        line-height: 18px;
    }
}
.login-way {
    .sms-way {
        background: #2c394e;
        border-radius: 25px;
        height: 50px;
        color: #ffffff;
        line-height: 50px;
        margin: 20px;
    }
}
.g-signin-button {
    /* This is where you control how the button looks. Be creative! */
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    background-color: #3c82f7;
    color: #fff;
    height: 32px;
    width: 200px;
    line-height: 32px;
    margin-top: 20px;
    font-size: 14px;
}
</style>
